@keyframes loading {
    0% { background-position: 0 0; }
    100% { background-position: 100% 0; }
}

.waiting-bar {
    width: 100%;
    height: 20px;
    background: linear-gradient(to right, #d3d3d3 30%, #e8e8e8 50%, #d3d3d3 70%);
    background-size: 200% 100%;
    animation: loading 1s linear infinite;
}

.actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px; /* ou 'mb-16' selon votre système de design */
}

.actions-left, .actions-right {
    display: flex;
    gap: 8px; /* Ajustez l'espacement entre les boutons selon vos besoins */
}