@import './Mixins.scss';

// Colors

$ambiant-color: #c71e21;
$primary-color: #075f94;
$secondary-color: #c71e21;
$light-secondary-color: #6dade6;
$danger-color: #f04134;
$success-color: #52c41a;
$info-color: #287fb5;

$black: #0c0b0f;
$white: #fdfdfd;
$dark-gray: #787878;
$medium-gray: #a5a5a5;
$light-gray: #d9d9d9;

$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
$font-color: rgba(0, 0, 0, 0.65);

$page-background: $white;

$sidebar-background: $ambiant-color;
$sidebar-selected-item-background: darken($sidebar-background, 20%);
$sidebar-footer-background: darken($sidebar-background, 5%);
$sidebar-sub-menu-background: darken($sidebar-background, 5%);

$header-background: $sidebar-background;
$header-color: $black;

// We have multiple shadow levels to represent perspective
$shadow-0: 0 2px 2px rgba(0, 0, 0, 0.1);
$shadow-1: 2px 4px 6px rgba(0, 0, 0, 0.1);
* {
    transition: box-shadow 0.1s ease-in;
}

@import './Markdown.scss';

/* Choices colors */
$choice-1-color: #fd3434;
$choice-2-color: #00008B;
$choice-3-color: #55c529;
$change-color: #3a89d3;