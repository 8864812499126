@import 'shared/style/Variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: $page-background;

  min-height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.clickable {
  cursor: pointer;

  &:hover {
    transform: scale(1.05, 1.05);
  }
}

@import 'shared/style/antd/_antd.scss';

.button-form {
  text-align: right;
}

h1 {
  text-transform: uppercase;
}

h3 {
  margin-top: 16px;
  color: rgba(#000, 0.65);
  font-size: 14px;
  font-weight: bold;
}

.page-title {
  text-transform: none;
  font-weight: bold;
  font-size: 24px;
}


.modals_dish {

  .modals_dish_header {
    color: #000000;
  }

  .modals_dish_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-self: center;
    gap: 8px;
    border-bottom: solid 1px #e8e5e5;
    padding: 8px 0;

    p {
      margin: auto 0;

    }

    .modals_dish_list_button {
        display: flex;
        justify-content: flex-end;
        margin: auto 16px;
    }
  }

  .modals_dish_footer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    gap: 16px;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  p {
    padding: 0;
  };
}

.mb-16 {
  margin-bottom: 24px;
}

.mt-16 {
  margin-top: 24px;
}


